import { colors } from 'utils/constants';
import Chevron from "components/Chevron";
import {extendClassName} from 'utils/common';
import { ReactNode } from 'react';

interface IToggleButtonProps {
  toggleHandler: () => void;
  chevronReversed: boolean;
  ignoreDefaultStyling?: boolean;
  children: ReactNode;
  additionalClassName?: string;
  style?: React.CSSProperties;
}

const ToggleButton = ({
  toggleHandler,
  chevronReversed,
  ignoreDefaultStyling = false,
  children,
  additionalClassName = "",
  style = {},
}: IToggleButtonProps) => (
  <div
    className={extendClassName("clickable", [
      !ignoreDefaultStyling ? "toggleButton" : "",
      additionalClassName,
    ])}
    onClick={toggleHandler}
    style={style}
  >
    <Chevron direction="down" reversed={chevronReversed} />
    {children}

    <style jsx>
      {`
        .toggleButton {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 5px 10px;
          color: ${colors.primaryBrandGray};
          align-self: flex-end;
          font-size: 0.9em;
          font-weight: 600;
          text-transform: uppercase;
          margin-left: auto;
          flex-grow: 0;
        }
      `}
    </style>
  </div>
);

export default ToggleButton;
