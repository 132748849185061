import BallMatchIndicator from 'components/landing/PrizesSection/BallMatchIndicator';
import { ASSET_PREFIX, colors } from 'utils/constants';
import Link from 'next/link';
import Chevron from 'components/Chevron';
import { PRIZE_TIERS } from '../constants/LottoPrizeValues';
import InternalLink from 'components/common/InternalLink';
import React from 'react';

const bonusBallColorForPrizeTableType = (prizeTableType, jackpotBoost) => {
  if (jackpotBoost) return PRIZE_TIERS.DAYMAKERboost.color;

  switch (prizeTableType) {
    case 'LITE':
      return '#FBB87D';

    case 'FREE':
      return '#FDABFF';

    default:
      return '#5AE2C0';
  }
};

const TokenInfoSection = () => {
  return (
    <div className="token-info-section grid">
      <p id="tokens-heading" className="xlarge xbold noMargin">
        DAYMADE Tokens
      </p>

      <p id="tokens-description" className="medium">
        Collect and redeem for FREE entries, epic gift cards, and more! Head on
        down to <InternalLink href="/token-town">Token Town</InternalLink> to
        spend your tokens.
      </p>

      <img
        id="tokens-img"
        src={`${ASSET_PREFIX}/images/landing/prizes/3-token-stack.png`}
        style={{ width: '141px' }}
      />

      <style jsx>
        {`
          .token-info-section {
            margin-top: 3em;
            grid-gap: 1em;
            grid-template-columns: 1fr;
            justify-items: center;
            text-align: center;
          }

          @media (min-width: 700px) {
            .token-info-section {
              justify-items: flex-start;
              text-align: left;
              grid-gap: 1em 2em;
            }

            #tokens-img {
              grid-column: 1;
              grid-row: 1 / 3;
            }

            #tokens-heading {
              grid-column: 2;
              grid-row: 1;
            }

            #tokens-description {
              grid-column: 2;
              grid-row: 2;
            }
          }
        `}
      </style>
    </div>
  );
};

const PrizeTableRow = ({
  mainNumberMatches,
  bonusBallMatch,
  prizeValue,
  prierTierDetails,
  withPrizesPageLinks,
  prizeTableType,
  jackpotBoost,
  withBottomBorder = true,
}) => {
  return (
    <div className="prizeTableRowContainer flexCentered">
      {jackpotBoost && (
        <div className="flexRow" style={{ marginBottom: '1em' }}>
          <img
            src={`${ASSET_PREFIX}/images/enter/rocket.png`}
            alt=""
            style={{ width: '20px', margin: '0 0.5em' }}
          />
          <p style={{ fontWeight: 800 }}>BOOSTED</p>
        </div>
      )}
      <div className="prizeTableRow fullWidth">
        <BallMatchIndicator
          mainNumberMatches={mainNumberMatches}
          bonusBallMatch={bonusBallMatch}
          bonusBallColor={bonusBallColorForPrizeTableType(
            prizeTableType,
            jackpotBoost
          )}
        />

        <div className="matchText">
          <p
            className="ignoreGlobalStyles"
            style={{ margin: 0, textTransform: 'uppercase', fontWeight: 800 }}
          >
            {mainNumberMatches} Main Number{mainNumberMatches !== 1 && 's'}
          </p>

          {bonusBallMatch && mainNumberMatches > 0 && (
            <p className="ignoreGlobalStyles">Plus the bonus ball</p>
          )}

          {bonusBallMatch && mainNumberMatches === 0 && (
            <p className="ignoreGlobalStyles">Bonus ball only</p>
          )}
        </div>

        <div className="prizeTier">
          <p
            className="ignoreGlobalStyles"
            style={{ fontWeight: '800', marginBottom: '0' }}
          >
            {prizeValue}
          </p>
        </div>

        <div className="prizeTierDescription">
          {prierTierDetails && (
            <p
              className="examplePrizes"
              style={{
                fontWeight: '600',
                marginBottom: '0.5em',
                fontSize: '1em',
              }}
            >
              {prierTierDetails.examplePrizes}
            </p>
          )}

          {withPrizesPageLinks && prierTierDetails && (
            <Link href={`/prizes?tier=${prierTierDetails.key}`}>
              <a className="prizesPageLink" target="_blank" rel="noreferrer">
                See prizes
                <Chevron
                  direction="right"
                  style={{
                    display: 'inline',
                    verticalAlign: 'middle',
                    margin: '0 0 0 5px',
                    fontSize: '0.8em',
                  }}
                />
              </a>
            </Link>
          )}
        </div>
      </div>
      <style jsx>
        {`
          .prizeTableRowContainer {
            padding: 1.5em 0.5em;
            ${jackpotBoost
              ? `
          background: ${colors.primaryBrandGray}15;
          outline: 10px solid ${colors.primaryBrandGray}15;
          border-radius: 5px;
          padding: 0.5em;
          margin: 1em 0;
        `
              : `
          border-bottom: ${withBottomBorder ? '2px solid #DDD' : 'none'}; 
        `}
          }

          .prizeTableRow {
            display: grid;
            grid-template-columns: 40% 55%;
            grid-gap: 0 5%;
            align-items: center;
          }

          :global(.ballMatchIndicator) {
            grid-row: 1;
            grid-column: 1;
          }

          .prizeTier {
            grid-column: 2;
            grid-row: 1;
            text-align: right;
            font-size: 0.9em;
          }

          .matchText {
            grid-column: 1;
            grid-row: 2;
            align-self: start;
            font-size: 0.9em;
            text-align: left;
          }

          .prizeTierDescription {
            grid-column: 2;
            grid-row: 2 / 3;
            font-style: italic;
            text-align: right;
            font-size: 0.9em;
          }

          @media (max-width: 350px) {
            .matchText {
              font-size: 0.8em;
            }

            .prizeTierDescription {
              font-size: 0.75em;
            }
          }

          @media (min-width: 500px) {
            .matchText {
              font-size: 1.2em;
            }

            .prizeTier {
              font-size: 1em;
            }

            .prizeTierDescription {
              font-size: 1em;
            }

            .prizeTableRow {
              grid-template-columns: 40% 55%;
              grid-gap: 0 5%;
            }
          }

          .prizesPageLink {
            color: #ff6d2c;
            font-style: italic;
            font-weight: 700;
            padding: 0.5em 0.5em 0.2em 0.8em;
            margin: -0.5em -0.5em -0.2em -0.8em;
            display: inline-block;
          }
        `}
      </style>
    </div>
  );
};

const PremiumPrizeTable = ({ expanded, withPrizesPageLinks }) => (
  <div style={{ width: '100%', maxWidth: '700px' }}>
    <PrizeTableRow
      prizeTableType="PREMIUM"
      mainNumberMatches={4}
      bonusBallMatch
      prizeValue="Any DAYMAKERboost prize"
      prierTierDetails={PRIZE_TIERS.DAYMAKERboost}
      withPrizesPageLinks={withPrizesPageLinks}
      jackpotBoost
    />

    <PrizeTableRow
      prizeTableType="PREMIUM"
      mainNumberMatches={4}
      bonusBallMatch
      prizeValue="Any DAYMAKER prize"
      prierTierDetails={PRIZE_TIERS.DAYMAKER}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="PREMIUM"
      mainNumberMatches={4}
      prizeValue="Any Diamond prize"
      prierTierDetails={PRIZE_TIERS.Diamond}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="PREMIUM"
      mainNumberMatches={3}
      bonusBallMatch
      prizeValue="Any Platinum prize"
      prierTierDetails={PRIZE_TIERS.Platinum}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="PREMIUM"
      mainNumberMatches={3}
      prizeValue="Any Gold prize"
      prierTierDetails={PRIZE_TIERS.Gold}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    {expanded && (
      <>
        <PrizeTableRow
          prizeTableType="PREMIUM"
          mainNumberMatches={2}
          bonusBallMatch
          prizeValue="Any Silver prize"
          prierTierDetails={PRIZE_TIERS.Silver}
          withPrizesPageLinks={withPrizesPageLinks}
        />

        <PrizeTableRow
          prizeTableType="PREMIUM"
          mainNumberMatches={2}
          prizeValue="Any Bronze prize"
          prierTierDetails={PRIZE_TIERS.Bronze}
          withPrizesPageLinks={withPrizesPageLinks}
        />

        <PrizeTableRow
          prizeTableType="PREMIUM"
          mainNumberMatches={1}
          bonusBallMatch
          prizeValue="£5 DAYMADE Credit"
        />

        <PrizeTableRow
          prizeTableType="PREMIUM"
          mainNumberMatches={1}
          prizeValue="1 Premium Entry"
        />

        <PrizeTableRow
          prizeTableType="PREMIUM"
          mainNumberMatches={0}
          bonusBallMatch
          prizeValue="1 DAYMADE Token"
        />

        <TokenInfoSection />
      </>
    )}
  </div>
);

const LitePrizeTable = ({ expanded, withPrizesPageLinks }) => (
  <div style={{ width: '100%', maxWidth: '700px' }}>
    <PrizeTableRow
      prizeTableType="LITE"
      mainNumberMatches={4}
      bonusBallMatch
      prizeValue="Any DAYMAKERboost prize"
      prierTierDetails={PRIZE_TIERS.DAYMAKERboost}
      withPrizesPageLinks={withPrizesPageLinks}
      jackpotBoost
    />

    <PrizeTableRow
      prizeTableType="LITE"
      mainNumberMatches={4}
      bonusBallMatch
      prizeValue="Any Diamond prize"
      prierTierDetails={PRIZE_TIERS.Diamond}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="LITE"
      mainNumberMatches={4}
      prizeValue="Any Platinum prize"
      prierTierDetails={PRIZE_TIERS.Platinum}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="LITE"
      mainNumberMatches={3}
      bonusBallMatch
      prizeValue="Any Gold prize"
      prierTierDetails={PRIZE_TIERS.Gold}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    <PrizeTableRow
      prizeTableType="LITE"
      mainNumberMatches={3}
      prizeValue="Any Silver prize"
      prierTierDetails={PRIZE_TIERS.Silver}
      withPrizesPageLinks={withPrizesPageLinks}
    />

    {expanded && (
      <>
        <PrizeTableRow
          prizeTableType="LITE"
          mainNumberMatches={2}
          bonusBallMatch
          prizeValue="Any Bronze prize"
          prierTierDetails={PRIZE_TIERS.Bronze}
          withPrizesPageLinks={withPrizesPageLinks}
        />

        <PrizeTableRow
          prizeTableType="LITE"
          mainNumberMatches={2}
          prizeValue="£5 DAYMADE Credit"
        />

        <PrizeTableRow
          prizeTableType="LITE"
          mainNumberMatches={1}
          bonusBallMatch
          prizeValue="1 Premium Entry"
        />

        <PrizeTableRow
          prizeTableType="LITE"
          mainNumberMatches={1}
          prizeValue="1 DAYMADE Token"
        />

        <PrizeTableRow
          prizeTableType="LITE"
          mainNumberMatches={0}
          bonusBallMatch
          prizeValue="1 DAYMADE Token"
        />

        <TokenInfoSection />
      </>
    )}
  </div>
);

const FreePrizeTable = ({ withPrizesPageLinks }) => (
  <div style={{ width: '100%', maxWidth: '700px' }}>
    <PrizeTableRow
      prizeTableType="FREE"
      mainNumberMatches={4}
      bonusBallMatch
      prizeValue="Any Platinum prize"
      prierTierDetails={PRIZE_TIERS.Platinum}
      withPrizesPageLinks={withPrizesPageLinks}
      withBottomBorder={false}
    />
  </div>
);

const WeeklyDrawPrizeTable = ({
  type = 'PREMIUM',
  expanded = true,
  withPrizesPageLinks,
}) => {
  switch (type) {
    case 'FREE':
      return <FreePrizeTable withPrizesPageLinks={withPrizesPageLinks} />;

    case 'LITE':
      return (
        <LitePrizeTable
          expanded={expanded}
          withPrizesPageLinks={withPrizesPageLinks}
        />
      );

    case 'PREMIUM':
    default:
      return (
        <PremiumPrizeTable
          expanded={expanded}
          withPrizesPageLinks={withPrizesPageLinks}
        />
      );
  }
};

export default WeeklyDrawPrizeTable;
