import React from 'react';
import Link from "next/link";

const InternalLink = ({ href, children }) => {
  return (
    <Link href={href}>
      <a className="underline">{ children }</a>
    </Link>
  );
};

export default InternalLink;
